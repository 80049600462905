import { CityStatus, TCityModel } from 'common/models/rpc/lime';
import { useCallback, useState } from 'react';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { Activate } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Activate';
import { Gear } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Gear';
import { limeTransport } from 'common/transport/lime';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { EditCityPopup } from './city-edit-popup';
import { propagateError } from 'common/utils/processing/propagate-error';
import { Spinner } from '@t15-ui-kit/circleLoader';
import { Avatar } from '@t15-ui-kit/avatar';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Action } from '@t15-ui-kit/action';
import { Grid } from '@t15-ui-kit/grid';
import { stringToColorUIKit } from 'common/utils/features/string-to-color';

type TCityCellProps = {
  city: TCityModel;
};

const dataAt = 'cityCell';

export const CityCell = ({ city }: TCityCellProps): JSX.Element => {
  const { status, name } = city;
  const [loading, setLoading] = useState(false);
  const firstLetter = city.name.charAt(0);
  const color = stringToColorUIKit(city.name);

  const isMobile = useMobile();

  const handleEditClick = useCallback(
    (field: string, value: unknown) => {
      setLoading(true);
      void limeTransport
        .editCity({ ...city, [field]: value })
        .then(() => {
          city[field] = value;
        })
        .catch(propagateError)
        .finally(() => {
          setLoading(false);
          uiKitService.closePopup();
        });
    },
    [city]
  );

  const actionList = loading ? (
    <Spinner />
  ) : (
    <Grid container elementOffset={['none', 'xs']}>
      <Grid item>
        <Action
          dataAt={`${dataAt}/toggleActivationAction`}
          cursor
          onClick={() =>
            handleEditClick('status', status === CityStatus.ACTIVE ? CityStatus.INACTIVE : CityStatus.ACTIVE)
          }
        >
          <Activate color={status === CityStatus.ACTIVE ? 'Success' : 'Alert'} />
        </Action>
      </Grid>
      <Grid item>
        <Action
          dataAt={`${dataAt}/editAction`}
          cursor
          onClick={() => uiKitService.openPopup(<EditCityPopup city={city} handleEdit={handleEditClick} />)}
        >
          <Gear />
        </Action>
      </Grid>
    </Grid>
  );

  return (
    <DCellLabel
      dataAt={dataAt}
      accLeft={
        <Avatar size="m" bgr={color} se>
          <Typography dataAt={`${dataAt}/firstLetter`} size="m" weight="700" color={ColorsBasicEnum.Default}>
            {firstLetter}
          </Typography>
        </Avatar>
      }
      accRight={actionList}
      offset={[isMobile ? 'xs' : 's', null]}
    >
      <Typography dataAt={`${dataAt}/name`} type="h3" size="l" weight="500" wordBreak="break-word">
        {name}
      </Typography>
    </DCellLabel>
  );
};
