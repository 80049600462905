import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { MeetingCardAddressAndTime } from 'common/components/ui/meeting-card/meeting-card-address-and-time';
import { MeetingCardCustomer } from 'common/components/ui/meeting-card/meeting-card-customer';
import { MeetingCardIcon } from 'common/components/ui/meeting-card/meeting-card-icon';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { formatDate } from 'common/utils/formatters/format-date';
import { formatTime } from 'common/utils/formatters/format-time';
import { memo, useCallback } from 'react';

type TMeetingCardProps = {
  data: MeetingDataModel;
  isWithComment?: boolean;
  onClick(data: MeetingDataModel): void;
};

export const MeetingCard = memo(({ data, isWithComment, onClick }: TMeetingCardProps): JSX.Element => {
  const isMobile = useMobile();

  const handleClick = useCallback((): void => {
    onClick(data);
  }, [data, onClick]);

  return (
    <DCellLabel
      accLeft={<MeetingCardIcon data={data} isExpired={false} />}
      onClick={handleClick}
      offset={[isMobile ? 'xs' : 's', null]}
    >
      <MeetingCardAddressAndTime data={data} isExpired={false} />
      <CellEmpty height="5" />
      <MeetingCardCustomer data={data} />
      {isWithComment && !!data.comments?.[0] && (
        <>
          <CellEmpty height="5" />
          <Typography type="div" color={ColorsBasicEnum.Secondary} size="s" weight="400">
            {data.comments[0].createdAt ? (
              <>
                {`${formatDate(data.comments[0].createdAt)} в ${formatTime(data.comments[0].createdAt)}`}
                <br />
              </>
            ) : null}
            {data.comments[0].value}
          </Typography>
        </>
      )}
    </DCellLabel>
  );
});
